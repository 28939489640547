const React = require('react');
const classnames = require('classnames');
const Props = require('./props');

const namespace = 'ui-homes-icon ui-homes-icon--chevron';
const ICON_ID = 'chevron_icon';

const IconChevron = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    viewBox="0 0 9 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconChevron.propTypes = Props.propTypes;
IconChevron.defaultProps = Props.defaultProps;

module.exports = React.memo(IconChevron);
exports.ICON_ID = ICON_ID;
