const React = require('react');
const classnames = require('classnames');
const Props = require('./props');

const namespace = 'ui-homes-icon ui-homes-icon--gift';
const ICON_ID = 'gift_icon';

const IconGift = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    viewBox="0 0 9 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconGift.propTypes = Props.propTypes;
IconGift.defaultProps = Props.defaultProps;

module.exports = React.memo(IconGift);
exports.ICON_ID = ICON_ID;
