const React = require('react');
const IconChevron = require('../../../commons/icons/chevron');
const IconCheck = require('../../../commons/icons/check');
const IconGift = require('../../../commons/icons/gift');

const availableIcons = {
  gift: IconGift,
  check: IconCheck,
  chevron: IconChevron,
};

const getIcon = (id) => {
  if (!id) return null;

  const Icon = availableIcons[id];
  if (Icon) {
    return <Icon />;
  }

  return null;
};

// TODO: eliminar esta funcion cuando el back tenga bien definido en que forma llegan los colores
const getRGBValue = (gradientColorInRGB) => {
  if (gradientColorInRGB) {
    const [r, g, b] = gradientColorInRGB.split(',');
    if (!g) return null;
    const newColor = [r, g, b].toString();
    return newColor;
  }
  return null;
};

const makeGradientMobile = (gradientColorInRGB) => {
  const normalizedColor = getRGBValue(gradientColorInRGB);
  return {
    backgroundImage: `linear-gradient(0deg, rgba(${normalizedColor}) 84px, rgba(0, 0, 0, 0) 100%)`,
  };
};

const makeGradientDesktop = (gradientColor) => {
  const normalizedColor = getRGBValue(gradientColor) || '0, 0, 0';
  return {
    background: `linear-gradient(0deg, rgb(${normalizedColor}) 0%, rgba(${normalizedColor}, 0.0001) 100%)`,
  };
};

const makeBackgroundColor = (backgroundColor) => {
  const normalizedColor = getRGBValue(backgroundColor);
  return normalizedColor
    ? { backgroundColor: `rgb(${normalizedColor})` }
    : null;
};

module.exports = {
  makeGradientMobile,
  makeBackgroundColor,
  makeGradientDesktop,
  getIcon,
};
